import '@popperjs/core'
import 'bootstrap'
import 'cocoon-js'
import 'select2/dist/css/select2.min.css'
import '../styles/application.scss'
import '../styles/css/style.css'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function () {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }
})
$(document).on('turbolinks:load', () => {
  $('.navbar-toggler').on('click', () => {
    $('body').addClass('menu-is-open')
  })
  $('.icon-toggle').on('click', () => {
    $('body').removeClass('menu-is-open')
  })
})
